<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-row :gutter="20">
        <el-col :span="3">simple: </el-col>
        <el-col :span="14">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}"  v-model="s3[0].title"></el-input>
        </el-col>
        <el-col :span="7">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}"  v-model="s3[0].content"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3">save: </el-col>
        <el-col :span="14">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}"  v-model="s3[1].title"></el-input>
        </el-col>
        <el-col :span="7">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}"  v-model="s3[1].content"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3">service: </el-col>
        <el-col :span="14">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}"  v-model="s3[2].title"></el-input>
        </el-col>
        <el-col :span="7">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}"  v-model="s3[2].content"></el-input>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div>公司简介</div>
      <el-row :gutter="20" justify="space-between" type="flex">
        <el-col :span="7">
          <editor :init="init" v-model="desc[0].content"></editor>
        </el-col>
        <el-col :span="7">
          <editor :init="init" v-model="desc[1].content"></editor>
        </el-col>
        <el-col :span="7">
          <editor :init="init" v-model="desc[2].content"></editor>
        </el-col>
      </el-row>
      <el-row :gutter="20" justify="space-between" type="flex">
        <el-col :span="7">
          <editor :init="init" v-model="desc[3].content"></editor>
        </el-col>
        <el-col :span="7">
          <editor :init="init" v-model="desc[4].content"></editor>
        </el-col>
        <el-col :span="7">
          <editor :init="init" v-model="desc[5].content"></editor>
        </el-col>
      </el-row>
      <el-row type="flex" justify="end">
        <el-button size="medium" type="primary" style="min-width: 120px" @click="submit">保存</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import {get3s, getIntro, update3s, updateIntro } from "@/plugins/api";

export default {
  name: 'Company',
  components: {
    'editor': Editor
  },
  data() {
    return {
      init: {
        language_url: '/static/tinymce/lang/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skin/oxide',
        height: 300,
        branding: false,
        content_style: "p {margin: 0}",
        menubar: false,
        toolbar: 'undo redo | bold fontsizeselect forecolor',
      },
      s3: [],
      desc: [],
    }
  },
  methods: {
    async get3s() {
      let res = await get3s();
      console.log(res)
      this.s3 = res
    },
    async getIntro() {
      let res = await getIntro();
      console.log(res)
      this.desc = res
    },
    async submit() {
      await this.s3.map(async x => {
          await update3s({
            id: x.id,
            title: x.title,
            content: x.content,
          })
      })
      await this.desc.map(async x => {
        await updateIntro({
          id: x.id,
          content: x.content,
        })
      })
      await get3s()
      await getIntro()
    }
  },
  created() {
    this.get3s();
    this.getIntro();
  }
}
</script>

<style scoped>
.el-row {margin-bottom: 10px}
</style>
